export default class TrackingService {

    constructor(name, category, scripts = []) {
        this.name = name;
        this.category = category;
        this.scripts = scripts;
        this.isLoaded = false;
    }

    /*
     * Loads scripts, if permission is given either explicitly
     * or implicitly in case of opt-in or opt-out, respectively.
     */
    load() {
        if(this.isLoaded) {
            return;
        }

        this.isLoaded = true;

        this.scripts.forEach(script => {
            if(typeof script === 'function') {
                script.call(this);
                return;
            }

            const tag = document.createElement('script');
            tag.async = true;
            tag.src = script;
            document.body.appendChild(tag);
        });
    }

}
