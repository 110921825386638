const GCLID_STORAGE_KEY = 'gclid';

export function persistGclid() {
    const url = new URL(window.location.href);

    if (!url) {
        return;
    }

    const value = url.searchParams.get('gclid');

    if (!value) {
        return;
    }

    const duration = 90 * 24 * 60 * 60 * 1000; // 90 days
    const expires = new Date().getTime() + duration;

    const data = { value, expires };
    const json = JSON.stringify(data);

    localStorage.setItem(GCLID_STORAGE_KEY, json);
}

export function getGclid() {
    const json = localStorage.getItem(GCLID_STORAGE_KEY);

    if (!json) {
        return null;
    }

    let data = null;

    try {
        data = JSON.parse(json);
    } catch (error) {
        return null;
    }

    if (!data || !data.value || !data.expires) {
        return null;
    }

    if (data.expires < new Date().getTime()) {
        return null;
    }

    return data.value;
}
