export default class CookieHub {

    constructor(id = null, services = [], config = {}) {
        if(!id) {
            console.error('[CookieHub] Missing site id');
        }

        this.state = null;
        this.element = null;

        this.id = id;
        this.services = services;
        this.config = config;

        this.options = {
            onInitialise: () => this.onInitialize(),
            onStatusChange: () => this.updateScripts(),
        };
    }

    init() {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function() { dataLayer.push(arguments); }
        gtag('consent', 'default', {
            security_storage: 'granted',
            functionality_storage: 'denied',
            personalization_storage: 'denied',
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'denied',
            wait_for_update: 500,
        });

        const firstScriptTag = document.querySelector('script');
        firstScriptTag.parentNode.insertBefore(this.scriptTag(), firstScriptTag);
    }

    onInitialize() {
        // Prevent a race condition when the CookieHub crawler scans the website
        setTimeout(() => this.updateScripts(), 0);

        this.addEventHandlers();

        if(this.config.hideDialog) {
            setTimeout(() => window.cookiehub.closeDialog(), 50);
        }
    }

    updateScripts() {
        this.services.forEach(service => {
            const isAllowed = this.cookiehub.hasConsented(service.category);
            const isLoaded = service.isLoaded;

            if(isLoaded && !isAllowed) {
                location.reload();
            }

            if(!isLoaded && isAllowed) {
                service.load()
            }
        });
    }

    addEventHandlers() {
        document.querySelectorAll('[data-cookiehub-open-settings]').forEach(element => {
            element.addEventListener('click', event => {
                event.preventDefault();
                this.cookiehub.openSettings();
            });
        });
    }

    scriptTag() {
        const scriptTag = document.createElement('script');
        scriptTag.async = true;
        scriptTag.src = `https://cookiehub.net/c2/${ this.id }.js`;

        scriptTag.onload = () => {
            this.cookiehub = window.cookiehub;
            this.cookiehub.load(this.options);
        };

        return scriptTag;
    }
}
