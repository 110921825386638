import TrackingService from './components/TrackingService/TrackingService.js';
import CookieHub from './components/CookieHub/CookieHub.js';
import { persistGclid } from './helpers/gclid.js';

const GOOGLE_TAG_MANAGER_ID = 'GTM-KCX2ZL4';
const COOKIEHUB_ID = '3aec3121';

// Set up Google Tag Manager, based on the GTM docs:
// https://developers.google.com/tag-manager/quickstart
const tagManager = new TrackingService(
    'tagManager',
    'marketing',
    [
        () => {
            window.dataLayer.push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
        },
        `https://www.googletagmanager.com/gtm.js?id=${GOOGLE_TAG_MANAGER_ID}`,
    ],
);

// Persist GCLID for add offline conversion tracking
// https://support.google.com/google-ads/answer/7012522?hl=en
const gclid = new TrackingService('gclid', 'marketing', [persistGclid]);

// Configure CookieHub
const services = [tagManager, gclid];

const cookieHub = new CookieHub(COOKIEHUB_ID, services, {
    // Do not ask for consent and do not load any third-party
    // scripts while on /legal-information
    hideDialog: window.location.pathname === '/legal-information',
});

cookieHub.init();
